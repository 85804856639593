.journal-page{
    min-height: 95vh;
    padding-bottom: 50px;
}
.year{
    border: 2px solid #eee;
    margin: 0 10px;
    padding: 20px;
    border-radius: 10px;
    font-size: 20px;
    background-color: white;
}

.data-list:hover {
    background-color: hsla(196, 32%, 35%, 1) !important;
    color: white;
}
.last-publish{
    margin: 40px;
    border: 1px solid #eee;
}
.full-data{
    margin: 30px 0;
}
.w-100{
    width: 100% !important;
}
.w-10{
    width: 10%;
}
.w-90{
    width: 90%;
}
/*div[data-index="1"] {
    display: none;
}*/
@media (max-width: 700px) {
    .w-10{
        width: 100%;
        text-align: center;
    }
}
@media (max-width: 426px) {
    .w-10{
        width: 100%;
        text-align: center;
    }
    .journal-page ul {
        padding: 0 ;
        list-style-type: none;
        display: none;
    }

    .journal-page ul {
        padding:  0 !important;
        list-style-type: none;
        display: none;
    }
    .dotted-desc {
        /* max-height: 130px; */
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 10px 20px;
    }
}
.research-card{
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    border-radius: 25px;
    color: rgb(98, 98, 98);
    position: relative;
    display:flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    text-align: left;
    margin: 10px 0;
    position: relative;

}
.research-card a{
    background-color: hsla(196, 32%, 35%, 1);
    color: white;
    padding: 10px 25px;
    margin-top: 10px;
    display: inline-block;
    border-radius: 9px;

}
.pub a{
    background-color: #BDA175;
    color: white;
    padding: 5px 25px;
    margin-top: 10px;
    display: inline-block;
    border-radius: 25px;
    border: 2px solid #BDA175;
}
.research-card a:hover{
    color: #BDA175;
    background-color: transparent;
}
.research-card:hover{
    transform: translateY(-5%);
    transition: 0.4s;
    box-shadow: 0px 0px 15px 3px #c2c2c2;
}
.research-data{
    display: flex;
    flex-wrap: wrap;
}
.full-data{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    justify-content: start;
    padding: 0 10%;
}
.publish-list{
    text-align: center;
}
.data-list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-weight: bold;
}
.data-list:hover{
    background-color: #BDA175;
    color: white;
}
.journal-page ul{
    padding: 20px;
    list-style-type: none;
    display: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.li{
    background: white;
    padding: 10px;
    border-radius: 10px;
    color: black !important;
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 10px;
}
.dotted-desc{
    /*max-height: 130px;*/
    text-overflow: ellipsis;
    overflow: hidden;

}