.fullNews {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    padding: 0 30px;
}

.news {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    padding: 1rem;
    box-shadow: 0 0 2px #ccc;
    transition: 0.3s;
    margin-bottom: 2rem;
}

.news:hover {
    box-shadow: 0 0 8px #ccc;
}
.newsTitle{
    font-size: 25px;
    font-weight: 600;
    color: black;
    text-align: end;
}
.newsDate{
    margin-bottom: 15px;
    display: flex;
    /* padding-right: 1rem; */
    /* width: 15%; */
    /* font-size: 15px; */
    color: gray;
}

.newsBody {

}
.newsBody .leftbody{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 25px;
    gap: 10px;
    box-shadow: 1px 2px 3px rgb(155 155 155 / 50%);
    padding: 10px;
    width: 100%;
    border-radius:10px;
}
.leftbody img{
    max-width: 300px;
    max-height: 300px;
}
.newsBody .rightbody{
    text-align: justify;
    white-space-collapse: break-spaces;
    direction: rtl;
}
@media(max-width:700px) {

}

.bold{
    font-weight: 900;
}
/*.news .title {
    display: flex;
    gap: 1rem;
}*/

.news .title h2 {
    font-size: 1.2rem;
    margin-bottom: 8px;
}

.news p {
    color: #888;
    margin-top: 1rem;
}

/*.news a{
    color: rgba(60, 101, 116, 1);
    font-weight: bold;
    text-decoration: underline;
}*/

.news a:active {
    transform: scale(0.9);
}

.news .title span {
    color: #777;
    font-size: 19px;
}

.news .newsDate .date {
    display: flex;
    padding-right: 1rem;
    width: 15%;
    font-size: 15px;
}
.date h4{
    margin: 0 2px;
}
.read-more a {
    background-color: hsla(196, 32%, 35%, 1) !important;
    padding: 7px 30px;
    border-radius: 5px;
    display: inline-block;
    margin-top: 15px;
    background-color: transparent;
    border: none;
    color: white;
}