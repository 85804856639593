.activities {
  min-height: 85vh;
}
.activities .fullEvents {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: left;
  gap: 20px;
  padding: 0 10px;
  margin: 20px 0;
}

@media (max-width: 768px) {
  .activities .fullEvents {
    width: 100%;
    justify-content: center;
  }
}
@media (max-width: 425px) {
}

