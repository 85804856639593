@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
*{
    font-family: 'Poppins', sans-serif;
}
.contact{
    min-height: 77vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 20px
}
.contact-data{
    margin-bottom: 0;
}
.contact .icon{
    color: hsla(196, 32%, 35%, 1);
    font-size: 30px;
}
.contact .icon-design{
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 40px;
    position: absolute;

    padding: 13px;
    border: 8px solid #eee;
    top: 0;
    left: 40%;
    transform: translate(-50%, -50%);
}
.location-contact{
    line-height: 30px;
    text-align: left;
    width: 400px !important;
    background-color: white;
    margin: 0 10px !important;
    padding: 10px;
    position: relative;
    min-height: 230px !important;
}
/*.location-contact:nth-child(1){
    line-height: 30px;
    text-align: left;
    width: 30% !important;
    display: block;
}*/

/*.location-contact:nth-child(2){
    line-height: 30px;
    text-align: left;
    width: 30% !important;
    display: block;
}*/
.contact .d-flex{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    gap: 30px;
    margin: 40px 0;
}

.data{
    padding: 30px 10px 30px 10px;
    width: 100%;

}
/*p{
    text-align: left;
}*/
@media (max-width: 768px) {

    /*.location-contact{
        line-height: 30px;
        text-align: left;
        width: 40% !important;
        display: block;
        background-color: white;
        margin: 0 10px  !important;
        padding: 10px  !important;
        position: relative;
        margin-top: 50px  !important;
    }
    .location-contact:nth-child(1){
        line-height: 30px;
        text-align: left;
        width: 40%  !important;
        display: block  !important;
        margin-top: 50px  !important;
    }
    .location-contact:nth-child(2){
        line-height: 30px;
        text-align: left  !important;
        width: 40%  !important;
        display: block  !important;
        margin-top: 50px  !important;
    }*/
}
@media (max-width: 425px) {

    .location-contact{
        line-height: 30px;
        text-align: left;
        width: 100%  !important;
        display: block;
        background-color: white;
        margin: 0 10px  !important;
        padding: 10px  !important;
        position: relative;
        margin-top: 50px  !important;

    }
    /*.location-contact:nth-child(1){
        line-height: 30px;
        text-align: left;
        width: 90%  !important;
        display: block;
        margin-top: 50px  !important;
    }
    .location-contact:nth-child(2){
        line-height: 30px;
        text-align: left;
        width: 90%  !important;
        display: block;
        margin-top: 50px  !important;
    }
    .location-contact:nth-child(3){
        line-height: 30px;
        text-align: left;
        width: 90%  !important;
        display: block;
        margin-top: 50px  !important;
        margin-bottom: 50px  !important;

    }*/
}