@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

html {
    scroll-behavior: smooth;
    font-family: 'Poppins', sans-serif;
}


.animate {
    position: relative;
    transform: translateY(60px);
    opacity: 0;
    transition: all 2s ease;
}

.active {
    transform: translateY(0px);
    opacity: 1;
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.loading img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    animation: logo 1s infinite;
}

@keyframes logo {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-15px);
    }

    100% {
        transform: translateY(0px);
    }
}

