.siteDesc .data p {
    margin-bottom: 0.6rem;
    text-align: justify;
    font-size: 17px;
    display: flex;
    align-items: start; 
    color: gray;
    position: relative;
    text-align: left;
}
.siteDesc .first-sec p{
    margin: 30px 0;
}
.siteDesc .first-sec p::before{
    content: " ";
    height: 100%;
    width: 2px;
    background: rgba(102, 102, 102, 1);
    position: absolute;
    top: 25px;
    left: 12px;

}
.second-sec{
    background: rgba(60, 101, 116, 1);

}
.siteDesc .data p:last-child::before{
    content: " ";
    height: 100%;
    width: 0;
    background: rgba(102, 102, 102, 1);
    position: absolute;
    top: 25px;
    left: 12px;

}
.mt-40{
    margin-top: 40px;
}
.elements{
    width: 47%;
    margin: 0  10px;
}
.Objectives{
    display: flex;
    flex-wrap: wrap;
    padding-top: 35px;
}
.ellisps{
    background: white;
    color: rgba(60, 101, 116, 1);
    padding: 6px 10px;
    margin-right: 10px;
    font-weight: bold;
    border-radius: 2px;
}
.ellisps-fields{
    background: linear-gradient(90deg,hsla(33, 88%, 57%, 1),hsla(10, 74%, 51%, 1));
    color: rgba(60, 101, 116, 1);
    padding: 6px 10px ;
    margin-right: 10px;
    font-weight: bold;
    border-radius: 2px;
    font-size: 15px;
    width: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
.Ellipse{
    height: 25px;
    margin-right: 5px ;
}
.siteDesc .data h4.subTitle {
    margin-top: 2rem;
    color: white;
    margin-bottom: 20px;
}
.color-white{
    color: white;
}

.objectives-p{
    width: 49%;
    display: inline-block;
}
.second-sec p{
    color: rgb(207 195 195) !important;

}
.header {
    background-color: rgb(235, 235, 235);
    font-size: 25px;
    padding: 20px 50px;
    font-weight: 600;
    margin-bottom: 50px;
    margin: 0;
}

.information {
    display: block;
    padding: 100px;
    justify-content: space-around;
    gap: 50px;
}

.information .desc {
    flex: 3
}

.information .list {
    flex: 2
}

.header-data {
    width: 60%;
}

.header-otherdata {
    width: 60%
}

.header-data h1 {
    font-size: 50px;
    margin-bottom: 50px;
    text-transform: capitalize;
}

.header-data span {
    color: #BDA175;
}

.header-data h5 {
    font-size: 20px;
    font-weight: 500;
}

.header-otherdata p {
    font-size: 20px;
}

.header-otherdata ul {
    padding: 20px 10px;
    list-style-type: none;
}

.header-otherdata li {
    padding: 10px 0;
}

li .icon {
    padding-right: 15px;
    color: #BDA175;
}

.siteDesc .data h1 {
    margin-bottom: 0.6rem;
    font-size: 26px;
    /* text-align: center; */
    margin-bottom: 2rem;
}


.siteDesc .data h4 {
    font-size: 20px;
    margin-bottom: 0.6rem;
}



.siteDesc .data ul {
    padding: 20px 10px;
    list-style-type: none;
    margin-left: 20px;
}

.siteDesc .data ul li {
    list-style-type: decimal;
    margin-bottom: 0.6rem;
    font-size: 17px;
}

@media (max-width: 768px) {
    .information {
        flex-wrap: wrap;
        padding: 40px 10px;
        gap: 10px;
        flex-direction: column;
        margin: 0 0px;
    }

    .header-data {
        width: 90%;
    }

    .header-otherdata {
        width: 90%;
        padding: 0
    }
    .elements{
        width: 100%;
        margin: 0  0;
    }
}