.members-page {
  min-height: 90vh;
}

.members-page {
  display: flex;
  gap: 2rem;
}

.fullmembers {
  padding: 0 30px;
  /* display: flex;
    flex-wrap: wrap; */
  /* justify-content: center; */
  gap: 20px;
  margin-bottom: 50px;
  flex: 7;
  margin-left: 350px;
}

.fullmembers .team-card {
  background-color: white;
}

.team-card {
  width: 90%;
  display: flex;
  /* height: 200px; */
  background-color: white;
  align-items: start;
  padding: 20px;
  gap: 10px;
  border-radius: 10px;
  box-shadow: 1px 1px 5px 5px #dfdfdf;
}

.team-card img {
  width: 30%;
  height: 80%;
  object-fit: contain;
  border-radius: 15px;
}

.team-name {
  font-size: 12px;
  font-weight: bold;
}

.team-card:hover {
  box-shadow: 1px 1px 10px 10px #c4c4c4;
  transition: 0.5s ease-in-out;
}

.team-data {
  text-align: justify;
  padding: 10px;
  margin: 15px 0 0 0;
  margin-top: 20px 0 0 0;
  /* overflow-y: scroll; */
}
.w-30 {
  width: 30%;
}

hr {
  background-color: #dfdfdf;
  border: none;
  height: 1px;
  margin: 10px 0;
}
.color-link {
  color: #0900ff;
  line-height: 1em;
  overflow-wrap: break-word;
}
.team-job {
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 12px;
  color: gray;
}

.filter {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: hsl(195.79deg 31.84% 35.1%);
  padding: 20px 0;
  height: fit-content;
  position: fixed;
  z-index: 10;
  margin: 20px 0px;
}

@media (max-width: 961px) {
  .members-page {
    display: block;
    gap: 2rem;
  }
  .filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    border-radius: 0;
    width: 100%;
    border-radius: 0px 0px 35px 35px;
    padding: 10px 0;
    margin: -95px 0;
  }
  .filter button {
    padding: 10px 10px;
  }
  .filter button span {
    position: absolute;
    bottom: -34px;
    left: 0;
    display: none;
    width: fit-content;
    text-align: center;
    text-wrap: nowrap;
  }
  .filter button:hover span {
    display: block;
  }
  .fullmembers {
    padding: 0 10px;
    /* justify-content: center; */
    gap: 20px;
    margin-bottom: 50px;
    flex: 7 1;
    margin-left: 0px;
    margin-top: 95px;
  }
}

.filter button {
  all: unset;
  padding: 1rem 1rem;
  border: 1px solid #dfdfdf;
  /* border-right: none; */
  cursor: pointer;
  transition: 0.3s;
  font-size: 18px;
  display: flex;
  gap: 2rem;
  color: white;
  border: none;
  position: relative;
  text-transform: capitalize;
}
.color-black {
  color: black;
}

.filter button img {
  width: 30px;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.filter button:active {
  scale: 0.98;
}

/*.filter button:last-child {
    border-right: 1px solid #dfdfdf;
}*/

.image-member {
  height: 140px;
  width: 200px !important;
}

.filter button.active {
  background-color: #dfdfdf;
  color: #000;
  /*width: -webkit-fill-available;*/
  background-color: white;
  border-radius: 10px;
}
