
.all-books{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    padding:0 15px;
}
.book {
    width: 100%;
    text-align: left;
    font-size: 15px;
    font-weight: 600;
    border: 1px solid #eee;
    padding: 15px;
    border-radius: 10px;
    display: flex;
    gap: 20px;
    align-items: center;
    box-shadow: 1px 2px 6px rgba(0,0,0,0.5);
}
.books-data{
    text-align: left;
}
.books-data .name{
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 10px;
    color: black;
}
.books-data p{
    font-size: 14px;
    font-weight: 400;
    color: gray;
}
.books-data a{
    background-color: hsla(196, 32%, 35%, 1) !important;
    padding: 7px 30px;
    border-radius: 5px;
    display: inline-block;
    margin-top: 15px;
    background-color: transparent;
    border: none;
    color: white;
}
.book:hover{
    box-shadow: 1px 1px 10px rgba(0,0,0,0.5);
    border: none;
}

.book img{
    width:150px;
    height: 200px;
}
@media (max-width: 426px) {
    .row .all-chapters {
        width: 100%;
    }
    .book{
        padding: 10px;
        margin: 20px 0;
        display: block;        
    }
    .book img{
        width: 100%;
        height: 180px;
        /* position: relative; */
        top: -35px;
        margin-bottom: 15px;
    }
}