.breadcrumbs{
    margin-bottom: 10px;
    border-radius: 15px;
	padding: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 17px;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">" !important;
}