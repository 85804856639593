
.chapters{
    min-height: 95vh;
}
.books{
}
.book-data{
    width: 100%;
    margin-bottom: 50px;
}
.row{
    display: flex;
    flex-wrap: wrap;
}
.row .all-chapters{
    width: 70%;

}
.book-header{

    display: flex;
    align-items: end;
    text-align: center;
}
.book-header .img1{
    width: 10%;
}
.book-header p{
    font-weight: bold;
    padding: 0 0px 20px 20px; 
}
.all-chapters{
    padding: 20px;
    border: 1px solid #eee;
}
.chapter-data{
    display: flex;
    gap: 30px;
    padding: 10px;
}
.all-chapters hr{
    width: 50%;
}
.row .img1 {
    width: 30%;
    height: 320px;
    /*display: none;*/
}
.chapter-data a{
    background-color: hsla(196, 32%, 35%, 1) !important;
    padding: 7px 30px;
    border-radius: 5px;
    display: inline-block;
    margin-top: 15px;
    background-color: transparent;
    border: none;
    color: white;
}
.index{
    color: hsla(196, 32%, 35%, 1) !important;
    font-size: 30px;
    font-weight: bold;
    border-right: 5px solid hsla(196, 32%, 35%, 1) !important;
    padding: 10px;
}
@media (max-width:1000px) {
    .book-header .img1{
        width: 13%;
    }
    .book-data{
        width: 100%;
    }
}
@media (max-width:750px) {
    .book-header .img1{
        width: 20%;
    }
    .book-data{
        width: 100%;
    }
}

@media (max-width: 426px) {
    .row .all-chapters {
        width: 100%;
        padding: 0;
    }
    .row .img1 {
        width: 100%;
        height: 320px;
        margin-bottom: 10px ;
        /*display: none;*/
    }
    .book-header{
        font-size: 20px;
        text-align: left;
    }
    .books {
        margin: 1%;
    }
    .journal-page ul {
     
    }
}