.national-page{
    min-height: 90vh;
}
.nationals ul{
    list-style-type: none;
} 
.nationals li p{
    padding: 20px 0;
}
.nationals li:hover{
    background-color: #eee
}
.nationals li{
    background-color: white;
    padding: 10px;
    font-weight: bold;
    margin: 15px 0;
    width: 100%;
    color: gray;
}
body{
    background-color:  rgb(242, 242, 242) !important;
}
.container{
    margin: auto;
}
.nationals ul{
    margin: auto;
}
.header{
    text-align: center;
    margin:0;
    font-weight: bold;
}
@media(max-width: 780px){
    .nationals ul{
        width: 90%;
        margin: 10px;
    }
}
