.events{
    margin-bottom: 10vh;
}

 .events p{
    padding:0;
    text-align: center;

}
.events .header p{
    text-align: center;
    font-size: 25px;
    font-weight: bold;
}

.events .fullEvents{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: left;
    gap: 20px;
    padding: 0 30px;
}
.events .event-card{
    margin-bottom: 30px;
    display: flex;
    width: 100%;
}
.events .fullEvents .card{

   background: white;

}
.events .fullData{
    display:flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
}
.events .card{
    background-color: #e2e2e2;
    min-height: 350px;
    border-radius: 25px;
    color: rgb(98, 98, 98);
    position: relative;
    display:flex;
    align-items: center;
    box-shadow: 0px 0px 15px 3px #c2c2c2;
}
.events .card:hover{
    transform: translateY(-5%);
    transition: 0.5s;
}
.events .card .event-image{
    width: 30%;
    height: 300px;
}
.content-event{
    width: 70%;
    min-height: 300px;
}
.mx-2{
    margin: 0 10px ;
}
.events .card img{
    border-radius: 20px 20px 0 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.events .title{
    margin-top: 22px;
    font-weight: bold;
    margin-bottom:25px;
    text-align: right;
    padding: 0 10px;
    font-size: 30px;
    text-align: center;
}
.events .event-data{
    display: flex;
    align-items: center;
    width: 100%;
    
}
.events .event-data .date{
    padding: 15px 15px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    display: flex;
}
.events .day{
    margin: 0 3px;
}
.events .d-flex{
    display: flex;
    flex-wrap: nowrap;

}
.events .location , .time{
    display: flex;
    font-weight: 600;
    font-size: 15px;
    display: flex;
    width: 100%;
    background: transparent;
}
.events .location{
    padding: 0 !important;
    margin: 0 15px !important;
    display: flex !important;
    width: 100% !important;
    background: transparent;

}

.events .fulldata{
    width: 100%;
}