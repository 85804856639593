* {
  margin: 0;
  padding: 0;
  box-sizing: 0;
}
.logo {
  font-style: italic;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 10px;
}

Navbar a {
  text-align: center;
  text-decoration: none;
  color: white;
}

.nav {
  top: 0;
  background-color: #3d6776;
  z-index: 100;
}

@media (max-width: 780px) {
  .nav-item {
    text-align: center;
    width: 100%;
    padding: 20px 0;
  }
}
