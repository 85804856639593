.international-page{
    min-height: 95vh;
    padding-bottom: 50px;
}
.full-paper{
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    margin-bottom: 20px;
}
@media(max-width: 780px){
    .full-paper{
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        justify-content: center;
        margin-bottom: 20px;
        padding: 40px 10px;
    }
}
body{
    background-color:  rgb(242, 242, 242);
}
.header{
    background: transparent;
    font-weight: bold;
    margin:0;
    
}
.inter-card{
    background-color: white;
    width: 100%;
    min-height: 100px;
    border-radius: 10px;
    font-size: 17px;
    font-weight: bold;
    color: rgb(98, 98, 98);
    position: relative;
    display:flex;
    align-items: center;
    flex-direction: column;
    padding: 30px;
}
.inter-data{
    width: 100%;
    text-align: left;
}
.inter-data a{
    background-color: hsla(196, 32%, 35%, 1) !important;
    padding: 7px 30px;
    border-radius: 5px;
    display: inline-block;
    margin-top: 15px;
    background-color: transparent;
    border: none;
    color: white;
}