body {
    margin: 0;
    padding: 0;
}
:root{
    --main-radius: 0 0px 30% 30%;
}
.slider {
    width: 100%;
    height: 65vh;
    position: relative;
    margin-top: 0vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-transition: background-image 1s ease-in-out;
    transition: background-image 1s ease-in-out;
    border-radius: var(--main-radius);
}

.slider img {
    width: 100%;
    height: 100%;
}

.slides {
    height: 65vh;
    display: flex;
    border-radius: var(--main-radius);
}

.home {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 65vh;
    border-radius:var(--main-radius);
    background-color: hsla(197, 100%, 17%, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.home .title {
    font-size: 50px;
    font-weight: bold;
    color: white;
}

.home p {
    font-size: 20px;
    font-weight: 600;
    color: white;
    text-transform: none !important;
}

.home a {
    border: 2px solid #BDA175;
    color: white;
    padding: 10px 30px;
    border-radius: 25px;
    margin-top: 5%;
    cursor: pointer;
    font-weight: bold;
    margin: 10px;
    background: linear-gradient(90deg,hsla(33, 88%, 57%, 1),hsla(10, 74%, 51%, 1));
    border: none;
}

.home a:hover {
    background-color: transparent;
    color: #BDA175;
    transition: 600ms;
}

@media(max-width:700px) {
    .home a {
        padding: 10px 30px;
        border-radius: 31px;
        margin-top: 11%;
        cursor: pointer;
        font-weight: bold;
        font-size: 12px;
    }

    .home .title {
        font-size: 30px;
    }

    .home p {
        font-size: 13px;
    }

}