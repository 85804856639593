.footer{
    width: 100%;
    text-align: center;
    background-color: hsla(196, 32%, 35%, 1) !important;
    color: white;
    /* box-shadow: 0px 7px 6px 7px #b6b6b6; */
    /* font-weight: bold; */
}

.footer p{
    padding: 20px;
    text-align: center;
    margin:0;

}

.footer span{
    color: #BDA175;
    margin:0;
}

.footer .devoloping_link{
    color: #ffffff;
}
.footer .devoloping_link:hover{
    color: #c8c8c8;
}